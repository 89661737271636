<template>
    <div class="main-content-area">
        <div class="main-content-toolbar">
            <v-toolbar :height="50">
                <v-btn icon="mdi-plus-thick" size="small" variant="text" :disabled="allowAdd == false"
                    @click="addStockContainerHandler" v-tippy="{ content: 'Add a new container' }"></v-btn>
                <v-btn icon="mdi-pencil" size="small" variant="text" :disabled="allowEdit == false" @click="editRecord"
                    v-tippy="{ content: 'Edit selected container' }"></v-btn>
                <v-btn v-if="store.user.permissions.includes('System Root/Stock List/Soft Delete Entries-Read')" icon="mdi-delete" size="small" variant="text" :disabled="allowDelete == false"
                    @click="deleteRecord" v-tippy="{ content: 'Delete selected containers' }"></v-btn>
                <v-btn icon="mdi-reload" size="small" variant="text" :disabled="allowAdd == false"
                    @click="common.reloadHandler(state, data, populateGridWithData)"
                    v-tippy="{ content: 'Reload container list' }"></v-btn>
                <v-btn icon="mdi-printer" size="small" variant="text" :disabled="allowPrint == false"
                    @click="printStockContainer"></v-btn>
                <v-btn icon="mdi-check" size="small" variant="text" :disabled="state.isEditing == 'no'"
                    @click="finishEditingContainer" v-tippy="{ content: 'Finish editing container' }"></v-btn>
                <v-btn icon="mdi-file-delimited-outline" size="small" variant="text" @click="exportCsv"
                    v-tippy="{ content: 'Export container list as CSV file' }"></v-btn>
                <v-btn icon="mdi-archive-marker-outline" size="small" variant="text" 
                    @click="exportPositionHistory"
                    v-tippy="{ content: 'Export CSV of position history of the selected container' }"></v-btn>
                <v-menu :close-on-content-click=false transition="none">
                    <template v-slot:activator="{ props }">
                        <v-btn icon="mdi-cog" dark v-bind="props" v-tippy="{ content: 'Show / Hide columns' }"></v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(columnContent, i) in columnDefs" :key="i" active-color="primary"
                            style="cursor:pointer;" @click="columnDefs[i].hide = !columnDefs[i].hide;">
                            <template v-slot:prepend>
                                <v-icon v-show="columnContent.hide" large color="#4E6F8E"
                                    style="margin-right: 15px;">mdi-checkbox-blank-circle-outline</v-icon>
                                <v-icon v-show="!columnContent.hide" large color="#4E6F8E"
                                    style="margin-right: 15px;">mdi-checkbox-marked-circle</v-icon>
                                <div style="font-size: 14px;"
                                    v-html="columnContent.headerName != '' ? columnContent.headerName : columnContent.headerComponentParams.template">
                                </div>
                            </template>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn icon="mdi-history" size="small" variant="text" 
                    :disabled="allowEdit==false"
                    @click="showHistory"
                    v-tippy="{ content: 'Show change history of the containers' }"
                    ></v-btn>
                <v-btn
                    icon="mdi-truck-delivery" 
                    size="small" 
                    variant="text" 
                    v-tippy="{ content: 'Gate-In Container' }"
                    :disabled="allowEdit == false || allowedGate!='in'"
                    @click="gateContainers('in')"></v-btn>
                <v-btn
                    icon="mdi-truck-delivery" 
                    size="small" 
                    variant="text"
                    style="transform: scaleX(-1)"
                    v-tippy="{ content: 'Gate-Out Container' }"
                    :disabled="allowEdit == false || allowedGate!='out'"
                    @click="gateContainers('out')"></v-btn>
                <v-btn v-if="store.user.permissions.includes('System Root/Stock List/View Deleted-Read')" :icon="filterRecords ? 'mdi-filter-remove-outline' : 'mdi-filter-check-outline' " size="small" variant="text" 
                    @click="toggleFilter"
                    v-tippy="{ content: filterRecords ?  'Include deleted containers' : 'Exclude deleted containers' }"
                    ></v-btn>
                <v-btn icon="mdi-receipt-send" size="small" variant="text" 
                    :disabled="!allowSendGateInReceipt"
                    @click="sendGateInReceipt"
                    v-tippy="{ content: 'Send/Resend Gate-In receipt to customer' }"
                    ></v-btn>
                <v-btn icon="mdi-receipt-send-outline" class="flip-horizontal" size="small" variant="text" 
                    :disabled="!allowSendGateOutReceipt"
                    @click="sendGateOutReceipt"
                    v-tippy="{ content: 'Send/Resend Gate-Out receipt to customer' }"
                    ></v-btn>
                <v-btn icon="mdi-step-backward" size="small" variant="text"
                    :disabled="allowRevert == false"
                    v-tippy="{ content: (selectedContainerStatus==2 || selectedContainerStatus==7) ? 'Revert Gate-In' : 'Revert Gate-Out' }"
                    @click="revertGateInOut"></v-btn>
            </v-toolbar>

            <v-toolbar :height="50">
                <v-btn icon="mdi-help-circle" size="small" variant="text"
                    v-tippy="{ content: 'Activate help system. <br>[ Development in progress ]' }"></v-btn>
                <AppNotifications />
            </v-toolbar>
        </div>
        <div class="main-content-playground">
            <!--Id is important for the few jquery selects that we are doing below in order not to affect another grid-->
            <ag-grid-vue 
                id="stockGrid" 
                ref="stockGrid" 
                style="width: 100%; height: 100%;" 
                class="ag-theme-alpine"
                :columnDefs="columnDefs" 
                :defaultColDef="defaultColDef" 
                :rowData="data.rowData"
                :pinnedTopRowData="data.pinnedTopRowData" 
                :gridOptions="gridOptions" 
                :editType="editType" 
                @contextmenu="stopEditingOnRightclick"
                >
            </ag-grid-vue>
        </div>

        <div class="main-content-footer">
            <v-toolbar :height="50">
                <v-switch :input-value="completedContainers" @change="changeCompletedContainers"
                    label="Gated Out Containers" style="width: 100%; margin-left: 10px; height: 55px;"
                    color="light-green darken-1" class="ag-theme-alpine">
                </v-switch>
            </v-toolbar>
            <AppMessageNotifications />
            <v-toolbar :height="50">
                {{ filterRecords ? 'Containers' : 'Containers (with deleted)' }}: {{ totalRecords }}
            </v-toolbar>
        </div>

        <DialogNeo dialog-title="Confirm" :show="state.dialog" dialog-width="25%" dialog-height="unset">
            <template #content>
                <v-container>
                    <v-row align="center">
                        <v-col>
                            Are you sure you want to delete the selected containers?
                            <div v-for="(item, index) in state.nodesToDelete" :key="index">
                                <!--{{ item.data.reference }}-->
                            </div>
                        </v-col>
                    </v-row>
                    <v-row align="end" justify="center">
                        <v-col align="right">
                            <v-btn color="primary" prepend-icon="mdi-delete" @click="cancelDeleteRecord">
                                <template v-slot:prepend>
                                    <v-icon color="white"></v-icon>
                                </template>
                                Cancel
                            </v-btn>
                            <v-btn color="error" prepend-icon="mdi-delete" @click="confirmDeleteRecord" style="margin-left: 15px;">
                                <template v-slot:prepend>
                                    <v-icon color="white"></v-icon>
                                </template>
                                Delete
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template #dialog-header-right>
                <v-btn icon="mdi-close-thick" size="small" variant="text" @click="cancelDeleteRecord"></v-btn>
            </template>
        </DialogNeo>

        <DialogNeo :dialog-title=" 'Confirm Gate-' + (state.gateType=='in' ? 'In' : 'Out') " :show="state.dialogGate" dialog-width="25%" dialog-height="unset">
            <template #content>
                <v-container>
                    <v-row align="center" style="height: 100%;">
                        <v-col>
                            <v-btn color="primary" prepend-icon="mdi-plus-circle" width="100%" @click="confirmGateContainers(true)">
                                <template v-slot:prepend>
                                    <v-icon color="white"></v-icon>
                                </template>
                                WITH - stacker job
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn color="primary" prepend-icon="mdi-minus-circle" width="100%" @click="confirmGateContainers(false)">
                                <template v-slot:prepend>
                                    <v-icon color="white"></v-icon>
                                </template>
                                WITHOUT - stacker job
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template #dialog-header-right>
                <v-btn icon="mdi-close-thick" size="small" variant="text" @click="cancelGateContainers"></v-btn>
            </template>
        </DialogNeo>

        <!--<div style="position:fixed;top:0px;right:0px;left:0;bottom:0;display: flex;flex-direction:column;z-index:1000000;">
            <div>
                <input :value="theValue" type="text" style="width:100%;height:50px;background-color:white;border:1px solid #4E6F8E;"/>
            </div>
            <div style="flex:1;position:relative;">
                <KeypadInput v-model:value="theValue"
                    backdrop="false"
                    :width="'30%'"
                    :height="'30%'"
                    show="true"
                    :all="keypadAllCharacters"
                    :allowed="keypadAllowedList"
                    :characters="4"
                    :characters_from="'end'"
                    :key_layout = "'keypad'"
                    input_selection = "false"
                    @update="updateTheValue"/>
            </div>
        </div>-->

        <DialogNeo v-if="history.dialog===true" :show=true :dialog-header=false :dialog-footer=false dialog-width="90%" dialog-height="90%">
            <template  #content>
                <HistoryList 
                    :history = "history"
                    @close="closeHistoryDialog" 
                    />
            </template>
        </DialogNeo>

        <DialogNeo :dialog-title=" 'Confirm Gate-' + (state.revertGateType=='in' ? 'In' : 'Out') + ' Revert'" :show="state.revertDialogGate" dialog-width="25%" dialog-height="unset">
            <template #content>
                <v-container>
                    <v-row align="center">
                        <v-col>
                            Are you sure you want to <b>Revert</b> the container <b>{{state.revertGateType=='in' ? 'Gate In' : 'Gate Out' }}</b> operation?
                        </v-col>
                    </v-row>
                    <v-row align="end" justify="center">
                        <v-col align="center">
                            <v-btn color="primary" @click="confirmRevertGateInOut()">
                                Ok
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template #dialog-header-right>
                <v-btn icon="mdi-close-thick" size="small" variant="text" @click="state.revertDialogGate = false"></v-btn>
            </template>
        </DialogNeo>        

    </div>
</template>

<script setup>

const theValue = ref(1);
const updateTheValue = (data) => {
    theValue.value = data.value;
}


//IMPORTS
import jQuery from "jquery";
import { inject, toRaw, ref, nextTick, reactive, onActivated, onDeactivated, onMounted, onBeforeMount, getCurrentInstance, computed } from 'vue'
import { useAppStore } from '@/stores/app'
import GridTooltip from '@/components/aggrid/GridTooltip'
import { useUtilities } from  '@/composables/useUtilities';
import { jsPDF } from "jspdf";
import { useStockListDefinition } from '@/components/aggrid/definitions/useStockListDefinition';
import { useCommon } from '@/components/aggrid/common/useCommon';
import { useRouter } from 'vue-router';
import { useAdvancedAxios } from '@/composables/useAdvancedAxios';
import { AgGridVue } from "ag-grid-vue3";
import { useKeyboard } from '@/composables/useKeyboard';
import _ from 'lodash';
import KeypadInput from '@/components/ui/KeypadInput';
import HistoryList from '@/components/HistoryList';



//REFERENCES
const stockGrid = ref(null);



//INJECTIONS
const axios = inject('axios');
const dayJs = inject("dayJs")
const router = useRouter();



//VARIABLES
let gridApi = null;
let gridApi2 = ref(null);
let columnApi = null;
let columnApi2 = ref(null);
let completedContainers = false;
let deactivatingCellEditors = false;



//CONSTANTS
const componentId = getCurrentInstance().uid;
const fields = {
    'stockContainers': [
        'id', 'stock_container_status_id', 'blocked', 'unit_number', 'iso_code', 'construction_year', 'depot_id', 'matched_shipping_line', 'matched_leasing_company', 'gate_in_date', 'turn_in_reference',
        'ib_actual_visit_mode', 'inbound_carrier_id', 'ib_actual_visit', 'gate_out_date', 'booking_number', 'ob_actual_visit_mode', 'outbound_carrier_id',
        'ob_actual_visit', 'color', 'floor', 'temperature', 'humidity', 'ventilation', 'comment', 'crun_id', 'created_at', 'updated_at', 'system',
        'position', 'condition'
    ]
};
const $ = jQuery;
const store = useAppStore()
const editType = 'fullRow';
const components = {
    AgGridVue
};
const data = reactive({
    rowData: null,
    pinnedTopRowData: [],
    pinnedBottomRowData: []
})
const state = reactive({
    allowUnpin: false,
    isEditing: 'no',
    dialog: false,
    dialogGate: false,
    revertDialogGate: false,
    gridReady: false,
    document: null,
    gateType: null,
    revertGateType: null
})
//const keypadSelection=ref(['Jonny bon Jovi', 'Ion cu Doua', 'Superman', 'Batman']);
//const keypadAllCharacters=ref(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]);
//const keypadAllCharacters=ref(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A","B","C","D","E","F","G","H","I","J","K","L","M", "T"]);
const keypadAllCharacters = ref(["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]);
const keypadAllowedList = ref(['121123', '111']);
const allowedGate = ref('none');
const triggerButtonsUpdate = ref(true);
const filterRecords = ref(true);
const totalRecords = ref('...');



//COMPUTED
const allowAdd = computed(() => {
    let t = triggerButtonsUpdate.value;
    return common.allowAdd(data, gridApi, state);
})
const allowEdit = computed(() => {
    let t = triggerButtonsUpdate.value;
    return common.allowEdit(data, gridApi, state);
})
const allowDelete = computed(() => {
    let t = triggerButtonsUpdate.value;
    return common.allowDelete(data, gridApi, state);
})
const allowPrint = computed(() => {
    let t = triggerButtonsUpdate.value;
    let rows = gridApi ? gridApi.getSelectedNodes() : 0;
    return rows.length == 1 ? true : false;
})
const allowSendGateInReceipt = computed(() => {
    let t = triggerButtonsUpdate.value;
    if (!gridApi) return false;
    let nodes = gridApi.getSelectedNodes();
    const total = nodes.length;
    if (total == 0) return false;
    for (let i = 0; i < total; i++) {
        if (nodes[i].data.stock_container_status_id != 2 && nodes[i].data.stock_container_status_id != 5) {
            return false;
        }
    }
    return true;
});
const allowSendGateOutReceipt = computed(() => {
    let t = triggerButtonsUpdate.value;
    if (!gridApi) return false;
    let nodes = gridApi.getSelectedNodes();
    const total = nodes.length;
    if (total == 0) return false;
    if (total == 1) {
        let node = nodes[0];
        return node.data.stock_container_status_id == 5 ? true : false;
    } else {
        return false;
    }
});
const allowRevert = computed(() => {
    let t = triggerButtonsUpdate.value;
    if (!gridApi) return false;
    let rows = gridApi.getSelectedNodes();
    if (rows.length == 1) {
        let row = rows[0];
        if (row.data.stock_container_status_id == 2 || row.data.stock_container_status_id == 7 || row.data.stock_container_status_id == 5 || row.data.stock_container_status_id == 8) {
            return true;
        }
    }
    return false;
});
const selectedContainerStatus = computed(() => {
    let t = triggerButtonsUpdate.value;
    if (!gridApi) return null;
    let rows = gridApi.getSelectedNodes();
    if (rows.length == 1 && (rows[0].data.stock_container_status_id == 2 || rows[0].data.stock_container_status_id == 7 || rows[0].data.stock_container_status_id == 5 || rows[0].data.stock_container_status_id == 8)) {
        return rows[0].data.stock_container_status_id;
    } else {
        return null;
    }
});



//COMPOSABLES
const { common } = useCommon(stockGrid, state, gridApi2, columnApi2, data);
const { advancedAxios } = useAdvancedAxios(axios);
const { getStockListDefinition } = useStockListDefinition(store, dayJs);
const { keyboard } = useKeyboard();
const { utilities } = useUtilities(store);

const columnDefs = ref([]);
const history = reactive({
    dialog: false,
    model: 'StockContainer',
    record: null,
    columnDefs: columnDefs,
    prepareRecord: prepareRecord,
    columnDefsOverride: [],
    columnDefsDisables: {}
});
const defaultColDef = reactive({
    resizable: true,
    editable: true,
    sortable: true,
    filter: true,
    floatingFilter: true,
    suppressMenu: false,        //To be set to true, hides filter in header
    filterParams: {
        debounceMs: 500,
        suppressAndOrCondition: true
    },
    floatingFilterComponentParams: {
        suppressFilterButton: false //To be set to true, hides filter button in floating filter
    },
    tooltipComponent: GridTooltip,
    suppressKeyboardEvent: function (params) {
        let event = params.event;
        let key = event.key;
        let deniedKeys = ['Escape'];
        let suppress = params.editing && deniedKeys.indexOf(key) >= 0;
        return suppress;
    },
})

const gridOptions = /*reactive(*/{
    ...common.gridOptions,
    onSortChanged: (e) => {
        //This method keeps the newly added records on top
        common.onSortChanged();
    },
    onCellContextMenu: (params) => {
        //common.onCellContextMenu(params, gridApi, state);
    },
    onFilterChanged: () => {
        common.onFilterChanged(gridApi);
    },
    onGridReady: (params) => {
        gridApi = params.api;
        gridApi2.value = params.api;
        columnApi = params.columnApi;
        columnApi2.value = params.columnApi;
        common.onGridReady(params, getStockListDefinition, columnDefs, state, populateGridWithData);
    },
    onRowEditingStarted: (params) => {
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
        common.onRowEditingStarted(params, state, componentId);
    },
    onRowEditingStopped: (params) => {
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    },
    onRowClicked: (params) => {
        if (params.data.id == "") {
            gridApi.startEditingCell({ rowIndex: params.rowIndex, colKey: state.firstVisibleColumn });
        }
    },
    onCellValueChanged(params) {
        common.onCellValueChanged(params, columnApi);
    },
    onRowValueChanged: (params) => {
        if (params.data.id == '' || common.editedRowChanged(state, params.data)) {
            let validation = validateRecord(params);
            if (validation.valid) {
                params.data.valid = true;
                let method = '';
                let url = '/yamax';
                if (params.data.id == '') {
                    method = 'post';
                } else {
                    method = 'put';
                    url += '/' + params.data.id;
                }
                let sendData = { ...params.data };
                //We don't need to send this data
                delete sendData.stock_containers__stock_container;
                delete sendData.depot__company;
                delete sendData.report__report;
                delete sendData.shipping_line__company;

                advancedAxios.sendRequest(componentId + (params.data.id ? params.data.id : params.data.uniqueId), { method: method, url: url, data: sendData })
                    .then(function (response) {
                        if (response.status == 200) {
                            //Update row with changes from backend
                            response.data.model.uniqueId = params.data.uniqueId;
                            response.data.model.valid = true;
                            common.updateChangesFromBackendOnUpdatingRows(response.data.model, gridApi, data, prepareRecord);
                            store.addNotificationMessage('Stock Container: <strong>' + (params.data.unit_unmber != '' ? params.data.unit_number : '') + '</strong> saved!', 'success');
                        } else {
                            params.data.valid = false;
                            params.node.setDataValue('valid', false);
                        }
                        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
                    }).catch(function (error) {
                        if (error.message !== 'canceled') {
                            params.data.valid = false;
                            params.node.setDataValue("valid", false);
                        }
                    });
                state.isEditing = 'no';
            } else {
                params.data.valid = false;
                params.node.setDataValue('valid', false);
                store.addNotificationMessage('Reference: <strong>' + params.data.reference + '</strong> has invalid data.\n' + validation.message, 'error');
            }
        }
        state.isEditing = 'no';
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    },

    onSelectionChanged: (params) => {
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;

        let nodes = gridApi.getSelectedNodes();
        const total = nodes.length;
        if (total == 1) {
            let node = nodes[0];
            if (node.data.stock_container_status_id == 1) {
                allowedGate.value = 'in';
            } else if (node.data.stock_container_status_id == 2) {
                allowedGate.value = 'out';
            } else {
                allowedGate.value = 'none';
            }
        } else {
            allowedGate.value = 'none';
        }
    },
    onModelUpdated: (params) => {
        totalRecords.value = gridApi ? gridApi.getDisplayedRowCount() : '...';
    },
    onPinnedRowDataChanged: (params) => {
        state.allowUnpin = (data.pinnedTopRowData.length === 0) ? false : true;
    },
    tabToNextCell(params) {
        return common.tabToNextCell(params, gridApi);
    },
    onBodyScroll: (event) => {
        if (!deactivatingCellEditors && event.direction == 'horizontal') {
            deactivatingCellEditors = true;
            common.deactivateAllCellEditors(gridApi);
            setTimeout(function () { deactivatingCellEditors = false; }, 2000);    //We do this so we don't trigger this method too many times and because bodyScrollEnd is not being triggered
        }
    },
    onBodyScrollEnd: (event) => {
        //This is currently not working but we will use it once the bug it's fixed by ag-grid developers
        deactivatingCellEditors = false;
    },
    onCellClicked: (params) => {
        common.onCellClicked(params, store);
    }
}



//EVENTS
onBeforeMount(() => { })
onMounted(() => { })
onActivated(() => {
    keyboard.addListener(componentId, onKeyPressHandler);
    keyboard.activate(componentId);
})
onDeactivated(() => {
    keyboard.removeListener(componentId);
})



//COMPUTED



//METHODS
const stopEditingOnRightclick = () => {
    common.stopEditingOnRightClick(state, gridApi);
}

const onKeyPressHandler = (event) => {
    if (event.shiftKey) {
        if (event.key == '+') { addStockContainerHandler(); }
    } else {
        common.onKeyPressHandler(event, gridApi, data, state);
    }
}

const populateGridWithData = () => {
    state.gridReady = false;
    setTimeout(() => {
        gridApi.showLoadingOverlay();
    });

    let url = '/api/yamax' + (completedContainers ? '?has_gate_out_date=true' : '?has_gate_out_date=false') + (filterRecords.value ? '&deleted=false' : '&deleted=true');
    common.normalServerReload(url, axios, state, gridApi2, fields, store, modelSubmodelMap, columnDefs, prepareRecord, common.prepareListRecord, data, onReloadSuccessError, onReloadSuccessError);
}

const onReloadSuccessError = (response) => {
    state.gridReady = true;
    triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
}

const addStockContainerHandler = () => {
    gridApi.setFilterModel(null);
    setTimeout(function () {
        gridApi.hideOverlay();
        state.isAdding = true;
        let newContainer = prepareRecord({
            new: 1
        });
        data.rowData.unshift(newContainer);
        gridApi.setRowData(data.rowData);
        nextTick(() => {
            let node = gridApi.getRowNode(newContainer.uniqueId);
            if (node) {
                gridApi.startEditingCell({ rowIndex: node.rowIndex, colKey: state.firstVisibleColumn });
                state.isEditing = 'existing';
            }
        })
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    }, 1);
}


//Gate In Containers
const gateContainers = (type) => {
    let nodes = gridApi.getSelectedNodes();
    let nonGateInable = 0;
    const total = nodes.length;
    state.nodesToGate = [];
    nodes.forEach(item => {
        state.nodesToGate.push(item);
    });
    state.gateType = type;
    state.dialogGate = true;
}
const confirmGateContainers = (withJob) => {
    state.dialogGate = false
    let gateData = [];
    let max = state.nodesToGate.length;
    for (let i = 0; i < max; i++) {
        gateData.push(state.nodesToGate[i].data.id);
    }
    axios.post('/stockContainer/' + gateData.join(',') + '/' + (state.gateType == 'in' ? 'gateIn' : 'gateOut') + '/' + (withJob ? 'withJob' : 'withoutJob')).then(function (response) {
        if (response.status === 200) {
            //Update row with changes from backend
            response.data.model.uniqueId = state.nodesToGate[0].data.uniqueId;
            common.updateChangesFromBackendOnUpdatingRows(response.data.model, gridApi, data, prepareRecord);
            //
            if (withJob) {
                store.addNotificationMessage("A job was created for container " + (state.nodesToGate[0].data.unit_number ? state.nodesToGate[0].data.unit_number : '') + " and it's going to be gated " + state.gateType + '!', 'success');
            } else {
                store.addNotificationMessage('Container: ' + (state.nodesToGate[0].data.unit_number ? state.nodesToGate[0].data.unit_number : '') + ' gated ' + state.gateType + '!', 'success');
            }

            state.nodesToGate = [];
        } else {
            state.nodesToGate[0].data.valid = false;
            state.nodesToGate[0].node.setDataValue('valid', false);
            store.addNotificationMessage('Container: ' + (state.nodesToGate[0].data.unit_number ? state.nodesToGate[0].data.unit_number : '') + ' could not be gated ' + state.gateType + '!\nReason: ' + response.data.message, 'error');
        }
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;

    }).catch(function (error) { });
}
const cancelGateContainers = () => {
    state.dialogGate = false
}

const revertGateInOut = () => {
    let row;
    let rows = gridApi.getSelectedNodes();
    if (rows.length == 1) {
        row = rows[0];
        if (row.data.stock_container_status_id == 2 || row.data.stock_container_status_id == 7) {
            state.revertGateType = 'in';
        } else if (row.data.stock_container_status_id == 5 || row.data.stock_container_status_id == 8) {
            state.revertGateType = 'out';
        }
    }
    state.revertDialogGate = true;
}
const confirmRevertGateInOut = () => {
    state.revertDialogGate = false;
    let revertType = null;
    let row;
    let rows = gridApi.getSelectedNodes();
    if (rows.length == 1) {
        row = rows[0];
        if (row.data.stock_container_status_id == 2 || row.data.stock_container_status_id == 7) {
            revertType = 'revertGateIn';
        } else if (row.data.stock_container_status_id == 5 || row.data.stock_container_status_id == 8) {
            revertType = 'revertGateOut/returnStockContainer';
        } else {
            return false;
        }
    } else {
        return false;
    }
    let container = row.data;

    let url = '/stockContainer/' + container.id + '/' + revertType;
    advancedAxios.sendRequest(componentId + container.uniqueId, { method: 'post', url: url })
        .then(function (response) {
            if (response.status === 200) {
                if (revertType == 'revertGateIn') {
                    let delIndex = data.rowData.findIndex(o => o.id === parseInt(response.data.model.id));
                    data.rowData.splice(delIndex, 1);
                    gridApi.setRowData(data.rowData);
                } else {
                    response.data.model.uniqueId = container.uniqueId;
                    response.data.model.valid = true;
                    common.updateChangesFromBackendOnUpdatingRows(response.data.model, gridApi, data, prepareRecord);
                }
                store.addNotificationMessage((revertType == 'revertGateIn' ? 'Gate-In' : 'Gate Out') + ' reverted for container: ' + (container.unit_number ? container.unit_number : ''), 'success');
            } else {
                store.addNotificationMessage((revertType == 'revertGateIn' ? 'Gate In' : 'Gate Out') + ' for container: ' + (container.unit_number ? container.data.unit_number : '') + ' could not be reverted!\nReason: ' + response.data.message, 'error');
            }
            triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
            //updateGatedContainers();
        }).catch(function (error) {
            //
        });
}

function prepareRecord(item){
    return {
        ...common.prepareRecord(item)
        ,
        ...{
            valid: true,
            blocked: item.blocked || '0',
            id: item.id || '',
            crun_id: item.id || '',
            turn_in_reference: _.get(item, 'return__reference.reference', '') || '',
            booking_number: _.get(item, 'release__reference.reference', '') || '',
            system: item.system || '',
            unit_number: item.unit_number || '',
            depot_id: item.depot_id || '',
            matched_leasing_company: item.matched_leasing_company || null,
            matched_shipping_line: item.matched_shipping_line || null,
            operator_name: item.operator_name || '',
            iso_code: item.iso_code || '',
            gate_in_date: item.gate_in_date || '',
            gate_out_date: item.gate_out_date || '',
            position: item.position || '',
            construction_year: item.report__report ? item.report__report.construction_year : item.construction_year,
            ib_actual_visit_mode: item.ib_actual_visit_mode || '',
            ib_actual_visit: item.ib_actual_visit || '',
            ob_actual_visit_mode: item.ob_actual_visit_mode || '',
            ob_actual_visit: item.ob_actual_visit || '',
            condition: item.condition ? item.condition : null,
            last_on_hire_date: item.last_on_hire_date || '',
            comment: item.comment || '',
            color: item.color || '',
            floor: item.floor || '',
            temperature: item.temperature || '',
            humidity: item.humidity || '',
            ventilation: item.ventilation || '',
            created_at: item.created_at || '',
            updated_at: item.updated_at || '',
            return__reference: item.return__reference,
            release__reference: item.release__reference,
            depot__company: item.depot__company,
            shipping_line__company: item.shipping_line__company,
            inbound_carrier_id: item.inbound_carrier_id || '',
            outbound_carrier_id: item.outbound_carrier_id || '',
            returning_company_name: item.returning_company_name,
            report__report: item.report__report,
            report: _.get(item, 'report__report.id', null),
            damaged: item.report__report ? getContainerStatus(item.report__report) : '',
            last_gate_in_yamax_task_status: item.last_gate_in_yamax_task__yamax_task ? item.last_gate_in_yamax_task__yamax_task.status : null,
            last_gate_out_yamax_task_status: item.last_gate_out_yamax_task__yamax_task ? item.last_gate_out_yamax_task__yamax_task.status : null,
            stock_container_status_id: item.stock_container_status_id ? item.stock_container_status_id : '',
            receipts: (item.gate_in_job_status_id ? item.gate_in_job_status_id : '') + (item.gate_out_job_status_id ? item.gate_out_job_status_id : ''),
            //Without grid columns
            gate_in_job_status_id: item.gate_in_job_status_id ? item.gate_in_job_status_id : null,
            gate_out_job_status_id: item.gate_out_job_status_id ? item.gate_out_job_status_id : null,
            run_state: item.report__report ? (item.report__report.revision__revision ? item.report__report.revision__revision.container_run_state : '' )  : '',
        }
    }
}

const getContainerStatus = (report) => {
    if (!report) {
        return 'No Report';
    }

    switch (report.damaged) {
        case 0: return 'Intact';
        case 1: return 'Damaged';
        case 2: return 'Instant Repair';
        default: return 'Unknown Status';
    }
}

const getReportStatus = (report) => {
    if (!report) {
        return 'No Report';
    }

    if (!report.revision__revision) {
        return 'No Revision';
    }
    switch (report.revision__revision.container_run_state) {
        case 1: return 'Awaiting Estimate';
        case 2: return 'Check Report';
        case 3: return 'Survey (Estimate)';
        case 4: return 'Work Order';
        case 5: return 'Survey (Post Repair)';
        case 6: return 'Re-Work Order';
        case 7: return 'Available';
        case 8: return 'Survey (On-Hire)';
        case 9: return 'Release as is';
        case 10: return 'Released';
        case 11: return 'Estimate';
        case 12: return 'Add. Estimate';
        case 13: return 'Survey (Add. Estimate)';
        case 14: return 'Add. Work Order';
        case 15: return 'Pre Delivery Estimate';
        default: return 'Unknown State';
    }
}

const editRecord = () => {
    gridApi.stopEditing();
    let rows = gridApi.getSelectedNodes();
    if (rows.length > 0) {
        let row = rows[0];
        gridApi.startEditingCell({ rowIndex: row.rowIndex, colKey: state.firstVisibleColumn });
        state.isEditing = 'existing';
    }
}
const cancelStockContainer = () => {
    state.dialog = false
}

const deleteRecord = () => {
    if (!common.deleteEditingRow(gridApi, data, state)) {
        let nodes = gridApi.getSelectedNodes();
        let nonDeletable = 0;
        const total = nodes.length;
        state.nodesToDelete = [];
        nodes.forEach(item => {
            state.nodesToDelete.push(item);
        });
        if (nonDeletable > 0) {
            if (total > 1) {
                if (nonDeletable == total) { store.addNotificationMessage("The containers already have a Gate-Out date and can't be deleted", 'warning'); }
                else if (nonDeletable > 0) { store.addNotificationMessage("Some containers already have a Gate-Out date and can't be deleted", 'warning'); }
            } else {
                if (nonDeletable == 1) {
                    store.addNotificationMessage("The container already has a Gate-Out date and can't be deleted", 'warning');
                }
            }
        } else {
            state.dialog = true;
        }
    } else {
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    }
}
const confirmDeleteRecord = () => {
    state.dialog = false
    let delData = [];
    let max = state.nodesToDelete.length;
    for (let i = 0; i < max; i++) {
        delData.push(state.nodesToDelete[i].data.id);
    }
    axios.delete('/stockContainer/' + delData.join(',')).then(function (response) {
        if (response.status == 200) {
            let deleted = response.data.deleted.length ? response.data.deleted.split(",") : [];
            let max = deleted.length;
            for (let i = 0; i < max; i++) {
                let delIndex = data.rowData.findIndex(o => o.id === parseInt(deleted[i]));
                data.rowData.splice(delIndex, 1);
                gridApi.setRowData(data.rowData);
            }
            state.nodesToDelete = [];
            triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
        }
    }).catch(function (error) { });
}
const cancelDeleteRecord = () => {
    state.dialog = false
}

const finishEditingContainer = (cancel = false) => {
    gridApi.stopEditing();
    state.isEditing = 'no';
    triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
}

const exportCsv = () => {
    common.exportCsv(gridApi, {
        processHeaderCallback: function (params) {
            switch (params.column.colId) {
                case 'report':
                    return 'SC Flow Report';
                default:
                    return params.column.colDef.headerName;
            }
        }
    }, {
        processCellCallback: function (cell) {
            if (cell.column.colId == 'receipts') {
                if (cell.node.data.gate_in_date != '' && cell.node.data.gate_out_date == '') {
                    return 'In';
                }
                if (cell.node.data.gate_in_date != '' && cell.node.data.gate_out_date != '') {
                    return 'In/Out';
                }
                return false;
            } else {
                return false;
            }
        }
    });
}

const savePdf = () => {
    let nodes = gridApi.getSelectedNodes();
    let data = nodes[0].data;
    let sendData = { document: state.document };
    axios({ method: 'POST', url: '/stockContainer/' + data.id + '/saveGateOutPdf', data: sendData }).then(function (response) {
        if (response.status == 200) {
            triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
        }
    });
}

const generatePdf = () => {
    let nodes = gridApi.getSelectedNodes();
    let data = nodes[0].data;
    let sendData = { document: state.document };

    axios({
        method: 'GET',
        url: '/stockContainer/' + data.id + '/generateGateOutPdf',
        responseType: 'arraybuffer',
        data: sendData
    }).then(function (response) {
        if (response.status == 200) {
            let blob = new Blob([response.data], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Report.pdf';
            link.click();
            //triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
        }
    })
}

const printStockContainer = () => {
    let nodes = gridApi.getSelectedNodes();

    let data = nodes[0].data;

    const doc = new jsPDF();
    doc.setFont('Arial', 'normal', 'bold');
    doc.setFontSize(12);
    doc.text("Gate-In Verification", 10, 14);
    doc.addImage("assets/maersk.jpg", "JPEG", 160, 12, 35, 19);

    doc.setFont('Arial', 'normal', 'normal');
    doc.setFontSize(11);

    doc.text("Reference No:", 10, 50);
    doc.text("Facility:", 10, 55);
    doc.text("Eir No:", 10, 60);
    doc.text("Container No:", 10, 65);
    doc.text("Gate-In Date:", 10, 70);
    doc.text("Company Name:", 10, 75);
    doc.text("License Plate:", 10, 80);
    doc.text("Inspection Condition:", 10, 85);
    doc.text("Remarks:", 10, 90);
    doc.text("Tracking Link:", 10, 95);

    doc.setFont('Arial', 'normal', 'bold');

    doc.text(data.return__reference ? data.return__reference.reference + '' : '', 50, 50);
    doc.text(data.depot__company ? data.depot__company.name : '', 50, 55);
    doc.text(data.crun_id + '', 50, 60);
    doc.text(data.unit_number + '', 50, 65);
    doc.text(utilities.dateFormatter({ value: data.gate_in_date, format: 'datetime' }), 50, 70);
    doc.text(data.shipping_line__company ? data.shipping_line__company.name : '', 50, 75);
    doc.text(data.ib_actual_visit, 50, 80);
    doc.text(data.comment + '', 50, 90);

    doc.addImage("assets/survey-compass.jpg", "JPEG", 85, 275, 35, 8.75);


    doc.setFont('Arial', 'normal', 'bold');
    doc.setFontSize(9);
    doc.text('Print Date & Time', 167, 115);
    doc.setFont('Arial', 'normal', 'normal');

    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    if (month < 10) { month = "0" + month; }
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();
    let newdate = year + "-" + month + "-" + day + " " + dateObj.getHours() + ":" + dateObj.getMinutes() + ":" + dateObj.getSeconds();

    doc.text(utilities.dateFormatter({ value: newdate, format: 'datetime' }), 167, 120);

    let document = doc.output('datauristring');
    state.document = document;

    doc.save("print.pdf");
}

const changeCompletedContainers = () => {
    completedContainers = !completedContainers;
    common.reloadHandler(state, data, populateGridWithData)
}

const showHistory = () => {
    let rows = gridApi.getSelectedNodes();
    if (rows.length > 0) {
        history.record = rows[0].data;
        history.dialog = true;
    }
}

const exportPositionHistory = () => {
    let selectedRows = gridApi.getModel().rowsToDisplay;

    if (selectedRows.length > 0) {
        const containerIds = selectedRows.map(row => row.data.id);
        console.log(containerIds);

        // Make a request to the server to get the position history for the selected containers
        axios.post('/api/stockContainer/positionHistory', { containerIds })
            .then(response => {
                const historyData = response.data;
                console.log(historyData);

                // Implement the logic to create the CSV string
                if (historyData) {
                    const excludedColumns = ['id', 'row_id', 'column'];
                    const csvContent = convertToCSV(historyData, excludedColumns);

                    console.log(csvContent);

                    // Save CSV file to the user
                    downloadCsvFile(csvContent, 'position_history.csv');
                }
            })
            .catch(error => {
                console.error('Error fetching position history:', error);
                // Handle the error, show a message, etc.
            });
    }
};

function convertToCSV(data, excludedColumns) {
    const header = [...Object.keys(data[0])].filter(column => !excludedColumns.includes(column)).join(',');

    const rows = data.map(entry => {
        const rowData = Object.entries(entry)
            .filter(([key]) => !excludedColumns.includes(key))
            .map(([, value]) => value)
            .join(',');

        return `${rowData}`;
    });

    return `${header}\n${rows.join('\n')}`;
}

const downloadCsvFile = (csvContent, fileName) => {
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
};

const closeHistoryDialog = () => {
    history.dialog = false;
}

const toggleFilter = () => {
    filterRecords.value = !filterRecords.value;
    common.reloadHandler(state, data, populateGridWithData);
}

const sendGateInReceipt = () => {
    let containers = [];
    let nodes = gridApi.getSelectedNodes();
    const total = nodes.length;
    for (let i = 0; i < total; i++) {
        containers.push(nodes[i].data.id);
    }
    axios.post('/stockContainer/' + containers.join(',') + '/sendGateInReceipt').then(function (response) {
        if (response.status === 200) {

            console.log('Response');
            console.log(response);

            //Update row with changes from backend
            /*response.data.model.uniqueId = state.nodesToGate[0].data.uniqueId;
            common.updateChangesFromBackendOnUpdatingRows(response.data.model, gridApi, data, prepareRecord);
            //
            if (withJob){
                store.addNotificationMessage("A job was created for container " + (state.nodesToGate[0].data.unit_number ? state.nodesToGate[0].data.unit_number : '') + " and it's going to be gated " + state.gateType+'!', 'success');
            }else{
                store.addNotificationMessage('Container: ' + (state.nodesToGate[0].data.unit_number ? state.nodesToGate[0].data.unit_number : '') + ' gated ' + state.gateType+'!', 'success');
            }

            state.nodesToGate = [];*/
            store.addNotificationMessage('Gate-In receipt was scheduled for sending', 'success');
        } else {
            store.addNotificationMessage('Containers: ' + (state.nodesToGate[0].data.unit_number ? state.nodesToGate[0].data.unit_number : '') + ' could not be gated ' + state.gateType + '!\nReason: ' + response.data.message, 'error');
        }
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    }).catch(function (error) { });
}

const sendGateOutReceipt = () => {
    let containers = [];
    let nodes = gridApi.getSelectedNodes();
    const total = nodes.length;
    for (let i = 0; i < total; i++) {
        containers.push(nodes[i].data.id);
    }
    axios.post('/stockContainer/' + containers.join(',') + '/sendGateOutReceipt').then(function (response) {
        if (response.status === 200) {

            console.log('Response');
            console.log(response);

            //Update row with changes from backend
            /*response.data.model.uniqueId = state.nodesToGate[0].data.uniqueId;
            common.updateChangesFromBackendOnUpdatingRows(response.data.model, gridApi, data, prepareRecord);
            //
            if (withJob){
                store.addNotificationMessage("A job was created for container " + (state.nodesToGate[0].data.unit_number ? state.nodesToGate[0].data.unit_number : '') + " and it's going to be gated " + state.gateType+'!', 'success');
            }else{
                store.addNotificationMessage('Container: ' + (state.nodesToGate[0].data.unit_number ? state.nodesToGate[0].data.unit_number : '') + ' gated ' + state.gateType+'!', 'success');
            }

            state.nodesToGate = [];*/
            store.addNotificationMessage('Gate-Out receipt was scheduled for sending', 'success');
        } else {
            store.addNotificationMessage('Containers: ' + (state.nodesToGate[0].data.unit_number ? state.nodesToGate[0].data.unit_number : '') + ' could not be gated ' + state.gateType + '!\nReason: ' + response.data.message, 'error');
        }
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    }).catch(function (error) { });
}

const validateRecord = (params) => {
    let ret = {
        valid: true,
        message: ''
    };

    ret.valid = true;
    return ret;
}

const modelSubmodelMap = {
    model: {
        'stockContainers': { target: function () { if (data.rowData === null) { data.rowData = []; } return data.rowData } }
    },
    submodels: {
        'matched_shipping_line': { target: function () { return columnDefs.value.find(def => def.field === 'matched_shipping_line') } },
        'depot_id': { target: function () { return columnDefs.value.find(def => def.field === 'depot_id') } },
        'matched_leasing_company': { target: function () { return columnDefs.value.find(def => def.field === 'matched_leasing_company') } },
        'ib_actual_visit_mode': { target: function () { return columnDefs.value.find(def => def.field === 'ib_actual_visit_mode') } },
        'ob_actual_visit_mode': { target: function () { return columnDefs.value.find(def => def.field === 'ob_actual_visit_mode') } },
        'condition': { target: function () { return columnDefs.value.find(def => def.field === 'condition') } },
        'iso_code': { id: 'code', name: ['code', 'survey_type__survey_type.name'], prepareRecord: common.prepareListRecord, target: function () { return columnDefs.value.find(def => def.field === 'iso_code').cellEditorParams.valueList } },
        'inbound_carrier_id': { prepareRecord: common.prepareListRecord, target: function () { return columnDefs.value.find(def => def.field === 'inbound_carrier_id').cellEditorParams.valueList } },
        'outbound_carrier_id': { prepareRecord: common.prepareListRecord, target: function () { return columnDefs.value.find(def => def.field === 'outbound_carrier_id').cellEditorParams.valueList } },
        'color': { target: function () { return columnDefs.value.find(def => def.field === 'color') } },
        'run_state': { target: function () { return columnDefs.value.find(def => def.field === 'run_state') } },
        'floor': { target: function () { return columnDefs.value.find(def => def.field === 'floor') } },
        'stock_container_status_id': { target: function () { return columnDefs.value.find(def => def.field === 'stock_container_status_id') } }
    }
};

</script>

<script>
export default {
    name: 'StockList',
    customOptions: {}
}
</script>